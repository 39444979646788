import { CommonModule, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { LanguageService } from '@core/services/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { DialogMessageGroup } from '../../store/dialog.selectors';

@Component({
  selector: 'nxt-dialog-messages',
  templateUrl: './dialog-messages.component.html',
  styleUrl: './dialog-messages.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, CommonModule, TranslateModule, MatIcon],
})
export class DialogMessagesComponent {
  currentLocale = inject(LanguageService).currentLocale;
  currentDate: string = this.getCurrentDate();
  dialogMessageGroups = new Array<DialogMessageGroup>();

  groupTitleDateFormat = 'dd.MMMM YYYY'; // if changed, getCurrentDate needs to be changed as well
  createdAtTimeFormat = 'HH:mm';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('dialogMessageGroups') set newValue(newValue: Array<DialogMessageGroup> | undefined) {
    if (!newValue) {
      return;
    }
    this.dialogMessageGroups = newValue;
    this.currentDate = this.getCurrentDate();
  }

  // needs to be changed as well, when groupTitleDateFormat is changed
  private getCurrentDate(): string {
    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }
}
