<div class="dialog-masterdata" *ngIf="dialogDetails" [formGroup]="dialogFormGroup">
  <div class="row mb-3">
    <label class="col-4">{{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.SUBJECT' | translate }}</label>
    <div class="col-8 wrapped">{{dialogDetails.subject}}</div>
  </div>
  <div class="row mb-3">
    <label class="col-4">{{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.RELATES_TO' | translate }}</label>
    <div class="col-8 nxt-primary-color nxt-underlined-text nxt-ellipsis"><ng-container *ngIf="dialogDetails.relatesTo; else noContent"><a [href]="dialogDetails.relatesToLink" (click)="navigatesTo($event)">{{dialogDetails.relatesTo}}</a></ng-container></div>
  </div>
  <div class="row mb-3">
    <label class="col-4">{{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.BUSINESS_TOPIC' | translate }}</label>
    <div class="col-8">
      @if (dialogDetails.assignedBusinessTopic === null) {
        {{ 'COMMON.DATA.NOT_AVAILABLE' | translate }}
      } @else {
        <ng-container *ngIf="dialogDetails.assignedBusinessTopic; else noContent">
          {{currentLang === 'en' ? dialogDetails.assignedBusinessTopic.nameEN : dialogDetails.assignedBusinessTopic.nameDE}}
        </ng-container>
      }
    </div>
  </div>
  <div class="row mb-3">
    <label class="col-4 align-content-center">{{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.WORK_AREA' | translate }}</label>
    <div class="col-8">
      @if (dialogDetails.workArea === null) {
        {{ 'COMMON.DATA.NOT_AVAILABLE' | translate }}
      } @else {
        <nxt-work-area-mini-box *ngIf="dialogDetails.workArea; else noContent" [boxColor]="dialogDetails.workArea.color" [boxText]="dialogDetails.workArea.displayName"/>
      }
    </div>
  </div>
  <div class="row mb-3">
    <label class="col-4">{{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.CREATED_AT' | translate }}</label>
    <div class="col-8">
      {{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.DATE.AT' | translate}} {{dialogDetails.createdAt | date: dateFormat}} {{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.TIME.AT' | translate}} {{dialogDetails.createdAt | date: timeFormat}}
    </div>
  </div>
  <div class="row mb-3">
    <label class="col-4">{{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.CHANGED_AT' | translate }}</label>
    <div class="col-8 align-content-center"><ng-container *ngIf="dialogDetails.changedAt; else noContent">
      {{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.DATE.AT' | translate}} {{dialogDetails.changedAt | date: dateFormat}} {{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.TIME.AT' | translate}} {{dialogDetails.changedAt | date: timeFormat}}
    </ng-container></div>
  </div>
  <div class="row mb-3">
    <label class="col-4">{{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.COMPANY' | translate }}</label>
    <div class="col-8 wrapped">
      @if (dialogDetails.assignedToCompany.deleted) {
        {{ 'COMMON.DELETED.LABEL.COMPANY' | translate }}
      } @else {
        {{ dialogDetails.assignedToCompany.companyName ?? '' }}
      }
      <ng-container *ngIf="dialogDetails.assignedToCompany?.companyIdentifier?.shortDescription">({{dialogDetails.assignedToCompany.companyIdentifier?.shortDescription}}: {{dialogDetails.assignedToCompany.companyIdentifier?.entry}})</ng-container>
    </div>
  </div>
  <div class="row mb-3">
    <label class="col-4 align-content-center">{{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.CHANGED_BY' | translate }}</label>
    <div class="col-8 align-content-center">
      @if (dialogDetails.changedBy.deleted) {
        {{ 'COMMON.DELETED.LABEL.USER' | translate }}
      } @else {
        <ng-container *ngIf="dialogDetails.changedBy.displayName; else noContent">
          {{ dialogDetails.changedBy.displayName }}
        </ng-container>
      }
    </div>
  </div>
  <div class="row mb-3">
    <label class="col-4 align-content-center">{{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.ASSIGNED' | translate }}</label>
    <div class="col-8 d-flex">
      <ng-container *ngIf="dialogDetails.assigned; else noAssignment">
        @if (dialogDetails.assignedToUser?.deleted) {
          {{ 'COMMON.DELETED.LABEL.USER' | translate }}
        } @else {
          <mat-icon class="nxt-primary-color me-3" aria-hidden="true">person_pin_circle</mat-icon>
          <span class="align-content-center">{{dialogDetails.assignedToUser?.fullName}} ({{dialogDetails.assignedToUser?.id}})</span>
        }
      </ng-container>
    </div>
  </div>
  <div class="row mb-3">
    <label class="col-4">{{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.STATUS' | translate }}</label>
    <div class="col-8">{{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.STATUS.' + dialogDetails.status | translate }}</div>
  </div>
  <div class="row mb-3">
    <label class="col-4">{{'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.NEXT_ID' | translate }}</label>
    <div class="col-8">{{dialogDetails.dialogId}}</div>
  </div>


</div>

<ng-template #noAssignment>
  <div>
    <span [attr.aria-label]="'DIALOG.OVERVIEW.AXA.ICON.UNASSIGNED' | translate">
      <mat-icon class="material-icons-outlined nxt-accent-500 me-3" aria-hidden="true">person_pin_circle</mat-icon>
      <mat-icon class="material-icons-outlined" aria-hidden="true">remove</mat-icon>
    </span>
  </div>
</ng-template>

<ng-template #noContent>
  <mat-icon class="material-icons-outlined" aria-hidden="true">remove</mat-icon>
</ng-template>
