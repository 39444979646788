import { ChangeDetectionStrategy, OnInit, Component, computed, OnDestroy, DestroyRef } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { RoutesConstants } from '@core/constants/routes.constants';
import { UniqueIDString } from '@shared/string-alias-types';
import { DialogSelectors } from '../store/dialog.selectors';
import { DialogModals, Overview } from '../store/dialog.actions';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FeatureToggleSelectors } from '@core/store/feature-toggles/feature-toggles.selectors';
import { DialogLegendComponent } from '../../@shared/components/dialog-legend/dialog-legend.component';
import { InfoPanelComponent } from '../../@shared/components/info-panel/info-panel.component';
import { DialogStatusIconComponent } from '../../@shared/components/dialog-status-icon/dialog-status-icon.component';
import { MatSort } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
  MatNoDataRow,
} from '@angular/material/table';
import { NgIf, DatePipe } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { OverviewFilterbarComponent } from '../../@shared/components/filterbar/filterbar.component';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButton, MatIconButton } from '@angular/material/button';
import { ButtonBarComponent } from '../../@shared/components/button-bar/button-bar.component';
import { BreadcrumbsComponent } from '../../@shared/components/breadcrumbs/breadcrumbs.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'nxt-dialog-overview',
  templateUrl: './dialog-overview.component.html',
  styleUrls: ['./dialog-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BreadcrumbsComponent,
    ButtonBarComponent,
    MatButton,
    MatTooltip,
    MatIcon,
    OverviewFilterbarComponent,
    MatDivider,
    NgIf,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    DialogStatusIconComponent,
    MatIconButton,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatNoDataRow,
    InfoPanelComponent,
    DialogLegendComponent,
    MatPaginator,
    DatePipe,
    TranslateModule,
    MatProgressSpinner,
    MatSlideToggle,
    FormsModule,
  ],
})
export class DialogOverviewComponent implements OnInit, OnDestroy {
  dateFormat = 'dd.MM.YYYY';

  displayedColumns = this.store.selectSnapshot(DialogSelectors.overviewColumns);
  pagination = toSignal(this.store.select(DialogSelectors.pagination));

  totalLength = toSignal(this.store.select(DialogSelectors.totalLength));

  dialogViewModel = toSignal(this.store.select(DialogSelectors.dialogs));
  filterState = toSignal(this.store.select(DialogSelectors.filterState));
  isAutoRefreshActive = false;

  featureToggles = toSignal(this.store.select(FeatureToggleSelectors.featureToggles));
  isCreateNewDialogButtonAvailable = computed(() => this.featureToggles()?.get('DIALOG:OVERVIEW|CREATE') ?? false);

  isOverviewInitialLoadCompleted = toSignal(this.store.select(DialogSelectors.isOverviewInitialLoadCompleted));

  get currentLang() {
    return this.translateService.currentLang;
  }

  constructor(
    private store: Store,
    private translateService: TranslateService,
    private destroyRef: DestroyRef
  ) {}

  ngOnDestroy(): void {
    this.store.dispatch(Overview.Leave);
  }

  ngOnInit(): void {
    this.store.dispatch(Overview.Enter);
    this.store
      .select(DialogSelectors.isAutoRefreshActive)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isAutoRefreshActive) => {
        this.isAutoRefreshActive = isAutoRefreshActive;
      });
  }

  onPageEvent(pageEvent: PageEvent) {
    this.store.dispatch(new Overview.Pagination.Set(pageEvent.pageSize, pageEvent.pageIndex));
  }

  goToDetailsPage(recordId: UniqueIDString<'Dialog'>) {
    this.store.dispatch(new Navigate([`${RoutesConstants.dialogRelative}/${recordId}`]));
  }

  createNewDialog() {
    this.store.dispatch(new DialogModals.NewDialogWithoutContext());
  }

  switchAutoRefresh() {
    this.store.dispatch(new Overview.ToggleAutoRefresh());
  }
}
