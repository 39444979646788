<div class="dialog-message-editor w-100 body-2">
    <div class="mb-4">
        <quill-editor data-test-id="editor-input" #editor class="w-100"
                [placeholder]="'DIALOG.DETAILS.PANEL_CHAT_HISTORY.EDITOR.PLACEHOLDER' | translate"
                [maxLength]="maxLength"
                [formats]="editorFormats"
                (onEditorCreated)="initContent()"
                [trimOnValidation]="true"
                (onContentChanged)="textChanged()">
                <div quill-editor-toolbar>
                    <span class="ql-formats d-flex align-items-center">
                      <button data-test-id="bold-btn" class="ql-bold" [title]="'Bold'"></button>
                      <span class="separator nxt-accent-200"></span>
                      <button data-test-id="italic-btn" class="ql-italic" [title]="'Italic'"></button>
                      <span class="separator nxt-accent-200"></span>
                      <button data-test-id="underline-btn" class="ql-underline" [title]="'underline'"></button>
                      <span class="separator nxt-accent-200"></span>
                      <button data-test-id="bullets-btn" class="ql-list" [title]="'Bullets'" value="bullet"></button>
                    </span>
                </div>
        </quill-editor>
        <span class="ms-3 mt-2 hint">
            @if (errorKey) {
                <mat-error>{{ editorContentLength }} / {{editor?.maxLength()}} - {{ errorKey | translate }}</mat-error>
            } @else {
                <mat-hint>{{ editorContentLength }} / {{editor?.maxLength()}}</mat-hint>
            }
        </span>
    </div>
    <nxt-button-bar>
        <button data-test-id="send-message-btn" mat-raised-button color="primary"
                (click)="sendMessage()" [disabled]="editorContentLength === 0 || errorKey || saveDisabled">
            <mat-icon class="material-icons-filled">send</mat-icon>
            {{'DIALOG.DETAILS.PANEL_CHAT_HISTORY.ACTION.SEND' | translate}}
        </button>
    </nxt-button-bar>
</div>
