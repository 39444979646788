<div class="wrapper">
    <nxt-breadcrumbs [ancestors]="[{
        relativePath: dialogOverviewRoute,
        displayName:('COMMON.MENU.DIALOG' | translate)
      }]" [currentComp]="'DIALOG.DETAILS.TITLE' | translate: {fileName: dialog()?.relatesTo}" />

    <div class="section-detail-page__header">
        <div data-test-id="dialog-details-title" class="header-row nxt-blue-marker">
            <h2 class="nxt-ellipsis">{{ 'DIALOG.DETAILS.TITLE'| translate: {fileName: dialog()?.relatesTo ?? ''} }}</h2>
            <h2 class="nxt-ellipsis">{{dialog()?.subject}}</h2>
        </div>
        <mat-toolbar class="section-detail-page-buttons">
          <div class="tools">
            <nxt-go-back (clickEmit)="goBack()" />
          </div>
          <div class="buttons">
            @if(dialog()?.assignmentAllowed) {
              <button mat-raised-button color="primary" data-test-id="assign-btn" (click)="assignDialogToMe()">
                <mat-icon class="material-icons-outlined">person_pin_circle</mat-icon>
                {{'DIALOG.DETAILS.ACTION.ASSIGN' | translate}}
              </button>
            }
            @else {
              <button mat-raised-button data-test-id="unassign-btn" (click)="unassignDialog()" [attr.aria-label]="'DIALOG.DETAILS.AXA.ACTION.UNASSIGN' | translate">
                <mat-icon class="material-icons-filled nxt-primary-color">person_pin_circle</mat-icon>
                {{'DIALOG.DETAILS.ACTION.UNASSIGN' | translate}}
              </button>
            }

          @if (dialog()?.archiveDialogAllowed) {
            <button mat-raised-button color="primary" data-test-id="archive-btn">
                <mat-icon class="material-icons-outlined">mark_chat_read</mat-icon>
                {{'DIALOG.DETAILS.ACTION.ARCHIVE' | translate}}
            </button>
          }
          <nxt-export-button />
          </div>
        </mat-toolbar>
    </div>

    <div class="d-flex flex-row justify-content-center align-items-stretch gap-3">
      <div class="w-50">
        <mat-expansion-panel class="flex-fill" expanded hideToggle disabled>
          <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="nxt-blue-marker">{{ 'DIALOG.DETAILS.PANEL_DIALOG_DETAILS.TITLE'| translate }}</h3>
              </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="w-100">
            <nxt-dialog-masterdata [readonly]="dialog()?.masterdataReadonly ?? true" [dialogDetails]="dialog()"></nxt-dialog-masterdata>
          </div>
        </mat-expansion-panel>

        <nxt-button-bar>
          @if (!dialog()?.masterdataReadonly) {
            <button data-test-id="close-btn" mat-raised-button color="primary"  (click)="navigateToOverview()">
              {{'COMMON.CLOSE' | translate}}
            </button>
          }
          @else {
            <button data-test-id="close-btn" mat-raised-button color="primary"  (click)="navigateToOverview()">
              {{'COMMON.CLOSE' | translate}}
            </button>
          }
        </nxt-button-bar>
      </div>

      <div class="w-50">
        <mat-expansion-panel class="flex-fill mb-2" expanded hideToggle disabled>
          <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="nxt-blue-marker">{{ 'DIALOG.DETAILS.PANEL_CHAT_HISTORY.TITLE'| translate }}</h3>
              </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="w-100">
            <nxt-dialog-message-editor
              [saveDisabled]="isMessageLoading() ?? false"
              [resetInput]="isMessageSentSuccessful() ?? false"
              (messageSend)="onMessageSend($event)"
              [validationErrors]="editorValidationErrors()" />
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="hasMessages()" class="flex-fill" expanded hideToggle disabled>
          <div class="w-100">
            <nxt-dialog-messages [dialogMessageGroups]="messages()"/>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
</div>
