import { ChangeDetectionStrategy, Component, computed, OnDestroy, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButton } from '@angular/material/button';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';
import { ActivatedRoute } from '@angular/router';
import { RoutesConstants } from '@core/constants/routes.constants';
import { TranslateModule } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { BreadcrumbsComponent } from '../../@shared/components/breadcrumbs/breadcrumbs.component';
import { ButtonBarComponent } from '../../@shared/components/button-bar/button-bar.component';
import { ExportButtonComponent } from '../../@shared/components/export-button/export-button.component';
import { GoBackComponent } from '../../@shared/components/go-back/go-back.component';
import { Details } from '../store/dialog.actions';
import { DialogSelectors } from '../store/dialog.selectors';
import { DialogMasterdataComponent } from './dialog-masterdata/dialog-masterdata.component';
import { DialogMessageEditorComponent } from './dialog-message-editor/dialog-message-editor.component';
import { DialogMessagesComponent } from './dialog-messages/dialog-messages.component';
import { RouterService } from '@core/services/router.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'nxt-dialog-details',
  templateUrl: './dialog-details.component.html',
  styleUrl: './dialog-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BreadcrumbsComponent,
    MatToolbar,
    GoBackComponent,
    MatButton,
    MatIcon,
    ExportButtonComponent,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    DialogMasterdataComponent,
    ButtonBarComponent,
    DialogMessageEditorComponent,
    DialogMessagesComponent,
    TranslateModule,
    NgIf,
  ],
})
export class DialogDetailsComponent implements OnInit, OnDestroy {
  dialogOverviewRoute = RoutesConstants.dialogRelative;

  dialog = toSignal(this.store.select(DialogSelectors.dialog));
  dialogMessage = toSignal(this.store.select(DialogSelectors.dialogMessage));
  messages = computed(() => this.dialogMessage()?.messageGroup);
  hasMessages = computed(() => {
    const messageGroups = this.messages();
    if (!messageGroups) {
      return false;
    }
    return messageGroups.length > 0;
  });
  isMessageLoading = computed(() => this.dialogMessage()?.editor.messageIsloading);
  isMessageSentSuccessful = computed(() => this.dialogMessage()?.editor.messageSentSuccessful);
  editorValidationErrors = computed(() => this.dialogMessage()?.editor.validationErrors);

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {
    const dialogId = this.activatedRoute.snapshot.paramMap.get('dialogId');
    this.store.dispatch(new Details.Enter(dialogId ?? 'unknown'));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new Details.Leave());
  }

  goBack() {
    if (!this.routerService.initialPage()) {
      history.back();
    } else {
      this.navigateToOverview();
    }
  }

  applyChanges() {
    this.store.dispatch(new Navigate([RoutesConstants.dialog]));
  }

  cancel() {
    this.store.dispatch(new Navigate([RoutesConstants.dialog]));
  }

  navigateToOverview() {
    this.store.dispatch(new Navigate([RoutesConstants.dialog]));
  }

  assignDialogToMe() {
    this.store.dispatch(new Details.ASSIGN());
  }

  unassignDialog() {
    this.store.dispatch(new Details.UNASSIGN());
  }

  onMessageSend(message: { text: string }) {
    this.store.dispatch(new Details.SendMessage(this.dialog()!.dialogId, message.text));
  }
}
