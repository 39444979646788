<div class="wrapper">

    <nxt-breadcrumbs [currentComp]="'COMMON.MENU.DIALOG' | translate"></nxt-breadcrumbs>

    <h2 data-test-id="feature-title" class="nxt-blue-marker">
      {{ 'DIALOG.OVERVIEW.TITLE'| translate }}
    </h2>

    <nxt-button-bar cssClass="mb-3">
      @if (isCreateNewDialogButtonAvailable()) {
        <button class="add-btn"
          data-test-id="add-record-btn"
          mat-raised-button color="primary"
          [matTooltip]="'DIALOG.OVERVIEW.ACTION.START_NEW_DIALOG' | translate"
          (click)="createNewDialog()">
              <mat-icon>add</mat-icon>
              {{'DIALOG.OVERVIEW.ACTION.START_NEW_DIALOG' | translate}}
        </button>
      }
    </nxt-button-bar>

    <nxt-filterbar
      [filterState]="filterState() ?? null" />

    <div class="section-table d-flex flex-column" aria-live="polite">
      <div class="overview-subtitle">
        <div class="d-flex flex-row align-items-center justify-content-between h-100">
          <h3 class="nxt-blue-marker">{{ 'DIALOG.OVERVIEW.TABLE.TITLE'| translate: {count: totalLength() ?? 0} }}</h3>
          <mat-slide-toggle
            [(ngModel)]="isAutoRefreshActive"
            [aria-label]="'slide-toggle'"
            (ngModelChange)="switchAutoRefresh()"
            color="primary">{{ 'COMMON.OVERVIEW.AUTO_REFRESH' | translate }}</mat-slide-toggle>
        </div>
      </div>
      <mat-divider class="w-100"/>

      <div class="flex-fill">
        <table data-test-id="data-table" mat-table matSort aria-label="Elements" [dataSource]="dialogViewModel()?.dialogs ?? []">

          <ng-container matColumnDef="assigned">
            <th mat-header-cell *matHeaderCellDef>{{'DIALOG.OVERVIEW.TABLE.ASSIGNED' | translate}}</th>
            <td mat-cell *matCellDef="let row">
              <span [attr.aria-label]="'DIALOG.OVERVIEW.AXA.ICON.ASSIGNED' | translate">
                <mat-icon *ngIf="row.assigned" class="nxt-primary-color" aria-hidden="true">person_pin_circle</mat-icon>
              </span>
              <span [attr.aria-label]="'DIALOG.OVERVIEW.AXA.ICON.UNASSIGNED' | translate">
                <mat-icon *ngIf="!row.assigned" class="material-icons-outlined nxt-accent-500" aria-hidden="true">person_pin_circle</mat-icon>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="businessTopic">
            <th mat-header-cell *matHeaderCellDef>{{'DIALOG.OVERVIEW.TABLE.BUSINESS_TOPIC' | translate}}</th>
            <td mat-cell *matCellDef="let row">{{ row.assignedBusinessTopic === null ?  ('COMMON.DATA.NOT_AVAILABLE' | translate) : currentLang === 'en' ? row.assignedBusinessTopic?.shortDescriptionEN ?? '' : row.assignedBusinessTopic?.shortDescriptionDE ?? '' }}</td>
          </ng-container>

          <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef>{{'DIALOG.OVERVIEW.TABLE.COMPANY_NAME' | translate}}</th>
            <td mat-cell *matCellDef="let row">{{row.assignedToCompany.companyName ?? ''}}</td>
          </ng-container>

          <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef>{{'DIALOG.OVERVIEW.TABLE.SUBJECT' | translate}}</th>
            <td mat-cell *matCellDef="let row">{{row.subject}}</td>
          </ng-container>

          <ng-container matColumnDef="relatesTo">
            <th mat-header-cell *matHeaderCellDef>{{'DIALOG.OVERVIEW.TABLE.RELATES_TO' | translate}}</th>
            <td mat-cell *matCellDef="let row">{{row.relatesTo}}</td>
          </ng-container>

          <ng-container matColumnDef="changedAt">
            <th mat-header-cell *matHeaderCellDef>{{'DIALOG.OVERVIEW.TABLE.CHANGED_AT' | translate}}</th>
            <td mat-cell *matCellDef="let row">{{row.changedAt | date: dateFormat}}</td>
          </ng-container>

          <ng-container matColumnDef="responseDueDate">
              <th mat-header-cell *matHeaderCellDef>{{'DIALOG.OVERVIEW.TABLE.RESPONSE_DUE_DATE' | translate}}</th>
              <td mat-cell *matCellDef="let row">{{row.responseDueDate | date: dateFormat}}</td>
          </ng-container>

          <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>{{'DIALOG.OVERVIEW.TABLE.STATUS' | translate}}</th>
              <td mat-cell *matCellDef="let row">
                <nxt-dialog-status-icon [statusValue]="row.status"/>
              </td>
          </ng-container>

          <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>{{'DIALOG.OVERVIEW.TABLE.ACTIONS' | translate}}</th>
              <td mat-cell *matCellDef="let row">
                <button mat-icon-button data-test-id="table-data-detail-btn" (click)="goToDetailsPage(row.dialogId)" [attr.aria-label]="'COMMON.EDIT_ITEM' | translate: {item:row['subject']}">
                  <mat-icon class="actions-icon-button">chevron_right</mat-icon>
                </button>
              </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="next-header-row"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [attr.data-test-id]="row.id"></tr>
          <!-- NOTE: will only work with css classes mat-row and mat-cell. With directive mat-row/mat-cell, neither row nor cell will be rendered! -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
              @if(isOverviewInitialLoadCompleted()) {
                <div  class="no-data-hint d-flex justify-content-center align-items-center">
                  <nxt-info-panel translationKey="DIALOG.OVERVIEW.TABLE.NO_DATA" />
                </div>
              } @else {
                <div class="d-flex justify-content-center align-items-center no-data-hint">
                  <mat-spinner diameter="21" />
                </div>
              }
            </td>
          </tr>
        </table>

      </div>
      <mat-divider class="w-100 legend-divider"/>
      <div class="px-4 py-4">
        <nxt-dialog-legend/>
      </div>
    </div>

    <div class="section-paginator">
      <mat-paginator
      [showFirstLastButtons]="true"
        [pageSize]="pagination()?.pageSize ?? 0"
        [pageSizeOptions]="pagination()?.pageSizeOptions ?? []"
        [length]="totalLength() ?? 0"
        (page)="onPageEvent($event)"
        [attr.aria-label]="'COMMON.PAGINATOR.PAGE_PAGINATION' | translate"
      />
    </div>
  </div>

