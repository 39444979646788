import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatHint } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationErrors } from '@shared/api/dialog-message/dialog-message.leaf';
import { ButtonBarComponent } from '@shared/components/button-bar/button-bar.component';
import { QuillEditorComponent } from 'ngx-quill';
@Component({
  selector: 'nxt-dialog-message-editor',
  templateUrl: './dialog-message-editor.component.html',
  styleUrl: './dialog-message-editor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    QuillEditorComponent,
    MatHint,
    MatError,
    DatePipe,
    ButtonBarComponent,
    MatButton,
    MatIcon,
    TranslateModule,
  ],
})
export class DialogMessageEditorComponent {
  @ViewChild('editor', { static: true }) quillEditor?: QuillEditorComponent;

  @Input() set resetInput(value: boolean) {
    this.resetMessage(value);
  }
  @Input() saveDisabled = false;
  @Input() set validationErrors(value: ValidationErrors | undefined) {
    if (value && value.get('msgTxt') && value.get('msgTxt')![0].translationKey === 'PROBLEMCODES.20012') {
      this.errorKey = 'DIALOG.DETAILS.PANEL_CHAT_HISTORY.EDITOR.FORMATS_ERROR';
    }
  }
  @Input() maxLength = 3000;
  @Input() set content(content: string) {
    this.editorContent = content;
  }

  @Output() messageSend = new EventEmitter<{ text: string }>();

  errorKey?: string;
  editorContent = '';
  editorContentLength = 0;

  editorFormats = ['bold', 'italic', 'underline', 'list'];

  initContent() {
    this.quillEditor!.writeValue(this.editorContent);
  }

  sendMessage() {
    this.messageSend.emit({ text: this.editorContent });
  }

  resetMessage(toReset: boolean) {
    if (toReset) {
      this.editorContent = '';
      this.initContent();
    }
  }

  textChanged() {
    this.errorKey = undefined;

    if (this.calculateRealTextLength(this.quillEditor!.quillEditor.getLength()) > this.maxLength) {
      this.quillEditor!.quillEditor.deleteText(this.maxLength, this.quillEditor!.quillEditor.getLength());
    }

    this.editorContent = this.quillEditor!.quillEditor.getSemanticHTML();
    this.editorContentLength = this.calculateRealTextLength(this.quillEditor!.quillEditor.getLength());
  }

  private calculateRealTextLength(editorLength: number) {
    return (editorLength ?? 0 > 0) ? (editorLength ?? 0) - 1 : 0;
  }
}
