import { Route } from '@angular/router';
import { isAuthCanActivateGuard } from '@core/guards/auth.guard';
import { featureToggleGuard } from '@core/guards/feature-toggle.guard';
import { DialogDetailsComponent } from './dialog-details/dialog-details.component';
import { DialogOverviewComponent } from './dialog-overview/dialog-overview.component';

export const DIALOG_ROUTES: Route[] = [
  {
    path: '',
    pathMatch: 'prefix',
    children: [
      {
        path: '',
        canActivate: [isAuthCanActivateGuard, featureToggleGuard('DIALOG:OVERVIEW')],
        component: DialogOverviewComponent,
      },
      {
        path: ':dialogId',
        canActivate: [isAuthCanActivateGuard, featureToggleGuard('DIALOG:DETAILS')],
        component: DialogDetailsComponent,
      },
    ],
  },
];
