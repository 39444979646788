import { DatePipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { WorkAreaMiniBoxComponent } from '../../../@shared/components/work-area-mini-box/work-area-mini-box.component';
import { DialogDetailsViewModel } from '../../store/dialog.selectors';

@Component({
  selector: 'nxt-dialog-masterdata',
  templateUrl: './dialog-masterdata.component.html',
  styleUrl: './dialog-masterdata.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    WorkAreaMiniBoxComponent,
    MatIcon,
    MatFormField,
    MatLabel,
    MatInput,
    MatSuffix,
    MatHint,
    DatePipe,
    TranslateModule,
  ],
})
export class DialogMasterdataComponent {
  dateFormat = 'dd.MM.YYYY';
  timeFormat = 'HH:mm:ss.SSS';

  dialogFormGroup = new FormGroup({});

  private _dialogDetails?: DialogDetailsViewModel | null | undefined;

  get currentLang() {
    return this.translateService.currentLang;
  }

  @Input() set dialogDetails(dialogDetails: DialogDetailsViewModel | null | undefined) {
    this._dialogDetails = dialogDetails;
  }

  get dialogDetails() {
    return this._dialogDetails;
  }
  @Input() readonly = false;

  constructor(
    private store: Store,
    private translateService: TranslateService
  ) {}

  navigatesTo(event: MouseEvent) {
    event.preventDefault();
    this.store.dispatch(new Navigate([this.dialogDetails?.relatesToLink]));
  }
}
