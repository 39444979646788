<div class="dialog-messages w-100">
    <div *ngFor="let msgGroup of dialogMessageGroups" class="d-flex flex-column mb-2">
        <div class="groupTitle mb-3 mat-caption">
          @if ( msgGroup.date === currentDate) {
            {{'DIALOG.DETAILS.PANEL_CHAT_HISTORY.LABEL.TODAY' | translate}}
          } @else {
            {{ msgGroup.date | date: groupTitleDateFormat:"":currentLocale() }}
          }
        </div>
        <div
            class="message  mb-3 d-flex flex-row"
            [ngClass]="{'OWN': msg.own}"
            *ngFor="let msg of msgGroup.messages"
            data-test-id="message">
              <div class="message-container d-flex flex-column flex-grow-1 px-3 py-3">
                  <div class="messageTitle mb-2 w-100">
                    <b>
                      @if (msg.senderDetails?.deleted) {
                        {{ 'COMMON.DELETED.LABEL.USER' | translate }},
                      } @else {
                        {{ msg.sender }},
                      }
                      {{ msg.createdAt | date: createdAtTimeFormat }}
                    </b>
                  </div>
                <div class="messageContent w-100" [innerHTML]="msg.text"></div>
              </div>
              <div data-test-id="message-confirmation" class="d-flex align-items-end" [class.unread] = "!msg.readByExternalUser">
                <mat-icon class="nxt-success-color">done</mat-icon>
              </div>
        </div>
      </div>
</div>
